<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="requForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('requForm')"
        :staFlag="false"
        :customFlag="true"
        :custom_status="custom_status"
      />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
      <!--      <el-button type="success" size="small" class="vd_get" @click="buttonReceive()"-->
      <!--                 :disabled="requForm.requ_status === 1"-->
      <!--      >接收-->
      <!--      </el-button>-->
      <!--      <el-button type="danger" size="small" class="vd_Back" @click="buttonBack()" v-if="requForm.requ_status === 0">-->
      <!--        退回-->
      <!--      </el-button>-->
      <el-button class="vd_export" style="left: 125px" type="success" size="small" @click="exportToExcel">导出Excel</el-button>
    </div>
    <el-form ref="requForm" :model="requForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="8">
          <el-form-item label="委托单号" prop="requ_no">
            <el-input
              disabled
              maxlength="10"
              v-model="requForm.requ_no"
              @input="requForm.requ_no = helper.keepEngNum2(requForm.requ_no)"
              placeholder="请填写委托单号"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="工厂打样单号" prop="fequ_no">
            <el-input disabled maxlength="10" v-model="requForm.fequ_no" placeholder="暂无工厂打样单号" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="委托人" prop="requ_stff_name">
            <!-- <el-input v-model="requForm.requ_stff_name" disabled placeholder="请填写委托人" show-word-limit>
            </el-input> -->
            <el-select
              filterable
              v-model="requForm.requ_stff_name"
              @change="requStffChange"
              placeholder="请选择委托人"
              size="small"
              clearable
            >
              <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.stff_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="要求交样时间" prop="requ_deli_time">
            <el-date-picker disabled v-model="requForm.requ_deli_time" type="date" placeholder="请选择时间日期"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="客户名称" prop="cust_abbr">
            <el-input disabled v-model="requForm.cust_abbr" show-word-limit placeholder="请选择客户名称" @click.native="custImport()">
              <template slot="append">
                <el-link :disabled="isShow" type="primary">选择</el-link>
              </template>
              {{ requForm.cust_abbr }}
            </el-input>
          </el-form-item>
          <el-dialog :title="CustTitle" :visible.sync="custFlag" width="70%">
            <requEditCust @custID="custID" @cancelCust="cancelCust"></requEditCust>
          </el-dialog>
        </el-col>
        <el-col :md="8">
          <el-form-item label="打样接收人" prop="requ_receive">
            <el-select disabled filterable v-model="requForm.requ_receive" placeholder="请选择打样接收人" size="small" clearable>
              <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.stff_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="客户国家">
            <el-input v-model="requForm.cust_country" placeholder="请填写客户国家" show-word-limit disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item
            label="打样部门"
            prop="belo_dept_id"
            :rules="requForm.smpl_list.length > 0 ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-select disabled @change="beloChange" v-model="requForm.belo_dept_id" placeholder="请选择打样部门" clearable>
              <el-option v-for="item in beloType" :key="item.param2" :label="item.param1" :value="item.param2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="委托备注" prop="requ_remark">
            <el-input
              type="textarea"
              rows="3"
              v-model="requForm.requ_remark"
              maxlength="5000"
              show-word-limit
              placeholder="请填写委托备注"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_solidDiv"></div>
      <div>
        <el-form :disabled="false" label-width="120px" size="mini">
          <div class="vd_but">
            <el-button type="success" size="small" @click="buttonReceive()" :disabled="false">接收</el-button>
            <el-button type="danger" size="small" @click="buttonBack()" :disabled="false">退单</el-button>
          </div>
        </el-form>
        <RequSmplList :isShow="isShow" :requForm="requForm" @handleSelectionChange="selectionChange"></RequSmplList>
      </div>
    </el-form>
    <el-dialog :title="title" :visible.sync="smplFlag" width="70%">
      <RequSmplResult @childSmpl="childSmpl" @childSmplChanel="childSmplChanel" ref="SmplEditIn"></RequSmplResult>
    </el-dialog>
    <el-dialog title="请填写退回原因！" style="text-align: center" :visible.sync="requBackFlag" width="25%">
      <el-form ref="form" :model="requ_back" label-width="80px">
        <el-form-item label="原因:" prop="requ_back_remark" :rules="{ required: true, message: ' ', trigger: 'blur' }">
          <el-input
            type="textarea"
            rows="7"
            v-model="requ_back.requ_back_remark"
            maxlength="500"
            show-word-limit
            placeholder="请填写退回原因"
          ></el-input>
          <el-form-item style="text-align: center; margin-top: 10px">
            <el-button type="primary" @click="requBackSubmit">确认</el-button>
          </el-form-item>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="requForm.stff_name"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, getNoCatch, post } from '@api/request';
import { requAPI } from '@api/modules/requ';
import { userAPI } from '@api/modules/user';
import { custAPI } from '@api/modules/cust';
import { optnAPI } from '@api/modules/optn';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import RequSmplList from './Componet/RequSmplList.vue';
import RequSmplResult from './Componet/RequSmplResult.vue';
import requEditCust from './Componet/RequEditCust.vue';
import bus from '@/components/common/bus';

export default {
  name: 'requEditMain',
  components: {
    editHeader,
    inputUser,
    RequSmplList,
    RequSmplResult,
    requEditCust
  },
  data() {
    return {
      rules: {
        requ_no: [{ required: true, trigger: 'blur', message: ' ' }],
        requ_stff_name: [{ required: true, trigger: 'change', message: ' ' }],
        requ_deli_time: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      requForm: {
        smpl_list: [],
        requ_receive: null,
        requ_stff_id: null,
        requ_stff_name: null,
        requ_stff_ename: null
      },
      requ_back: {
        requ_back_remark: null
      },
      isShow: true,
      btn: {},
      stffForm: {},
      stffType: [],
      requCopy: [],
      npsmplListCopy: [],
      selectChangeList: [],
      smplFlag: false,
      requBackFlag: false,
      title: '样品总控表',
      custList: [],
      contryName: null,
      loadingFlag: true,
      beloType: [],
      custFlag: false,
      CustTitle: '选择客户',
      custom_status: { name: '待接收', type: 'info' }
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getCustList();
      this.getrequInfo();
      this.getStffType();
      this.getContry();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              this.$message.warning('请把必填内容补充完整');
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let requForm = {};
      requForm = Object.assign(requForm, this.requForm);
      requForm.requ_deli_time = parseInt(Number(requForm.requ_deli_time) / 1000);
      this.requCopy = this.requCopy.filter(item => requForm.smpl_list.every(item1 => item.smpl_id !== item1.smpl_id));
      this.npsmplListCopy = this.npsmplListCopy.filter(item => requForm.npsmpl_list.every(item1 => item.npsm_id !== item1.npsm_id));
      for (let i = 0; i < this.requCopy.length; i++) {
        let requTime = parseInt(new Date().getTime() / 1000);
        this.requCopy[i].delete_time = requTime;
      }
      for (let i = 0; i < this.npsmplListCopy.length; i++) {
        let requTime = parseInt(new Date().getTime() / 1000);
        this.npsmplListCopy[i].delete_time = requTime;
      }
      requForm.smpl_list = requForm.smpl_list.concat(this.requCopy);
      requForm.smpl_list = requForm.smpl_list.concat(this.npsmplListCopy);
      for (let i = 0; i < requForm.smpl_list.length; i++) {
        if (requForm.smpl_list[i].toys_type === 0) {
          requForm.smpl_list[i].smpl_deil_time = parseInt(requForm.smpl_list[i].smpl_deil_time / 1000);
        }
      }
      if (requForm.smpl_list.length === 0) {
        if (!requForm.belo_dept_id) {
          requForm.belo_dept_id = 0;
        }
      }
      post(requAPI.editRequ, requForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '物料编号重复'
            });
            requForm.requ_deli_time = Number(requForm.requ_deli_time) * 1000;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            // this.initData()
            requForm.requ_deli_time = Number(requForm.requ_deli_time) * 1000;
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('requForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    //更改状态
    getStatusName(status) {
      switch (status) {
        case 0:
          this.custom_status = { name: '待接收', type: 'info' };
          break;
        case 1:
          this.custom_status = { name: '全部接收', type: 'success', effect: 'Dark' };
          break;
        case 2:
          this.custom_status = { name: '部分接收', type: 'success' };
          break;
        case 3:
          this.custom_status = { name: '部分退回', type: 'warning', effect: 'Dark' };
          break;
        default:
          this.custom_status = { name: '错误', type: 'danger' };
          break;
      }
    },
    // 变更打样部门
    beloChange(val) {
      for (let i in this.requForm.smpl_list) {
        this.requForm.smpl_list[i].belo_dept_id = val;
      }
    },
    // 更换委托人获取英文名
    requStffChange() {
      for (let i = 0; i < this.stffType.length; i++) {
        if (this.requForm.requ_stff_name === this.stffType[i].stff_name) {
          this.requForm.requ_stff_ename = this.stffType[i].stff_ename;
        }
      }
    },
    // 选择客户名称变化国家
    // changeCustId(val){
    //   if(val){
    //     for(let i=0;i<this.custList.length;i++){
    //       if(this.custList[i].cust_name === val){
    //         this.requForm.cust_country = this.custList[i].cust_country
    //       }
    //     }
    //   }else{
    //     this.contryName = null
    //   }
    // },
    //打开客户弹窗
    custImport() {
      if (!this.isShow) {
        this.custFlag = true;
      }
    },
    //关闭客户弹窗
    // 关闭弹框
    cancelCust() {
      this.custFlag = false;
    },
    // 客户信息子传父值
    custID(val) {
      if (val.cust_id) {
        this.requForm.cust_id = val.cust_id;
        this.requForm.cust_abbr = val.cust_abbr;
        this.requForm.cust_country = val.cust_country;
        this.contryName = val.cust_country;
      } else {
        this.requForm.cust_id = 0;
        this.requForm.cust_abbr = 0;
        this.requForm.cust_country = 0;
        this.contryName = 0;
      }
      if (val.cust_pid) {
        this.requForm.cust_pid = val.cust_pid;
      } else {
        this.requForm.cust_pid = val.cust_id;
      }
      if (val.cust_name) {
        this.requForm.cust_name = val.cust_name;
      } else {
        this.requForm.cust_name = null;
      }
      if (val.cust_mail) {
        this.requForm.cust_mail = val.cust_mail;
      } else {
        this.requForm.cust_mail = null;
      }
      if (val.cust_fcp_flag) {
        this.requForm.cust_fcp_flag = val.cust_fcp_flag;
      } else {
        this.requForm.cust_fcp_flag = 0;
      }
      this.custFlag = false;
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    //接收按钮
    buttonReceive() {
      if (this.selectChangeList.length <= 0) {
        return this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      }
      if (this.selectChangeList.filter(x => x.requ_sample_status !== 0).length > 0) {
        return this.$message({ type: 'warning', message: '只可接收待接收数据！' });
      }
      let ids = Array.from(this.selectChangeList, ({ smpl_id }) => smpl_id).join(',');
      this.$confirm('确认接收?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          post(requAPI.ReceiveRequById, { requ_id: this.requForm.requ_id, smpl_ids: ids })
            .then(res => {
              if (res.data.code === 0) {
                //刷新首页推送数量
                bus.$emit('refreshReminderCount');
                //刷新首页推送list
                bus.$emit('refreshRequPush9004');
                bus.$emit('dashRequPush9004');
                this.$message({
                  type: 'success',
                  message: '接收成功'
                });
                this.initData();
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg
                });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            });
        })
        .catch(() => {});
    },
    //退回按钮
    buttonBack() {
      if (this.selectChangeList.length <= 0) {
        return this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      }
      if (this.selectChangeList.filter(x => x.requ_sample_status !== 0).length > 0) {
        return this.$message({ type: 'warning', message: '只可退回待接收数据！' });
      }
      this.requBackFlag = true;
    },
    //退回确认按钮
    requBackSubmit() {
      if (this.selectChangeList.length <= 0) {
        return this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      }
      if (!this.requ_back.requ_back_remark) {
        return this.$message({ type: 'error', message: '请填写退回原因！' });
      }
      let ids = Array.from(this.selectChangeList, ({ smpl_id }) => smpl_id).join(',');
      this.$confirm('确定退回此条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          post(requAPI.BackRequById, {
            requ_id: this.requForm.requ_id,
            requ_back_remark: this.requ_back.requ_back_remark,
            smpl_ids: ids
          })
            .then(res => {
              if (res.data.code === 0) {
                //刷新首页推送数量
                bus.$emit('refreshReminderCount');
                //刷新首页推送list
                bus.$emit('refreshRequPush9004');
                bus.$emit('dashRequPush9004');
                this.requ_back.requ_back_remark = null;
                this.requBackFlag = false;
                this.$message.success(res.data.msg);
                //全部退回关闭页面
                if (this.selectChangeList.length === this.requForm.smpl_list.length) {
                  this.$EventBus.$emit('close_current_tags');
                }
                this.initData();
                // await this.$router.push({
                //   path: '/requ_list',
                //   query: {
                //     perm_id: 111
                //   }
                // });
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    // 打开弹框
    smplImport() {
      this.smplFlag = true;
    },
    // 导入传值
    childSmpl(val) {
      let value = JSON.parse(JSON.stringify(val));
      for (let i = 0; i < value.length; i++) {
        let ids = JSON.parse(JSON.stringify(value[i].smpl_id));
        value[i].smpl_deil_time = null;
        value[i].smpl_suffix = null;
        value[i].part_name = null;
        value[i].smpl_type = 2;
        value[i].toys_type = 0;
        value[i].smpl_cust_num = 0;
        value[i].areTheyEqual = false;
        value[i].temp_smpl_id = ids;
        value[i].smpl_id = null;
        value[i].belo_dept_id = this.requForm.belo_dept_id;
      }
      this.requForm.smpl_list = this.requForm.smpl_list.concat(value);
      for (let i = 0; i < this.requForm.smpl_list.length; i++) {
        this.requForm.smpl_list[i].key = Number(i);
      }
      this.$refs.SmplEditIn.clear();
      this.smplFlag = false;
    },
    // 取消选择
    childSmplChanel() {
      this.smplFlag = false;
    },
    // 样品list步骤新增
    addRow() {
      let item = {
        smpl_no: null,
        smpl_cust_no: null,
        smpl_name: null,
        smpl_stff_id: null,
        smpl_stff_name: null,
        part_name: null,
        smpl_suffix: null,
        toys_type: 0,
        smpl_type: null,
        smpl_price: null,
        smpl_verify_price: null,
        smpl_num: null,
        smpl_deil_time: null,
        requ_id: 0,
        destroy_flag: 0,
        smpl_cust_num: 0,
        belo_dept_id: this.requForm.belo_dept_id,
        key: this.requForm.smpl_list.length
      };
      this.requForm.smpl_list.push(item);
      for (let i = 0; i < this.requForm.smpl_list.length; i++) {
        this.requForm.smpl_list[i].key = Number(i);
      }
    },
    // 样品list清单多选删除
    delBom() {
      if (this.selectChangeList.length > 0) {
        let temp = [];
        for (let i = 0; i < this.selectChangeList.length; i++) {
          let ind = null;
          ind = this.requForm.smpl_list.indexOf(this.selectChangeList[i]);
          temp.push(ind);
        }
        temp = temp.sort((a, b) => {
          return b - a;
        });
        for (let j of temp) {
          this.requForm.smpl_list.splice(j, 1);
        }
        for (let i = 0; i < this.requForm.smpl_list.length; i++) {
          this.requForm.smpl_list[i].key = Number(i);
        }
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    // 样品单list选择
    selectionChange(val) {
      this.selectChangeList = val;
    },
    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code === 0) {
            let stffType = res.data.data;
            let map = new Map();
            for (let item of stffType) {
              map.set(item.stff_name, item);
            }
            stffType = [...map.values()];
            this.stffType = stffType;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取打样部门
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let beloType = res.data.data.form.optn_cntt_list;
            for (let item of beloType) {
              item.param2 = Number(item.param2);
            }
            this.beloType = beloType;
          }
        })
        .catch(() => {});
    },
    // 获取客户国家
    getCustList() {
      get(custAPI.getAllCustsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.custList = res.data.data.list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getrequInfo() {
      this.requForm.requ_id = this.$route.query.form_id;
      get(requAPI.getRequById, { requ_id: this.requForm.requ_id })
        .then(res => {
          if (res.data.code === 0) {
            this.requForm = res.data.data.form;
            this.requForm.requ_deli_time = Number(this.requForm.requ_deli_time) * 1000;
            this.requCopy = JSON.parse(JSON.stringify(this.requForm.smpl_list));
            this.npsmplListCopy = JSON.parse(JSON.stringify(this.requForm.npsmpl_list));
            let listCopy = JSON.parse(JSON.stringify(this.requForm.npsmpl_list));
            this.requForm.smpl_list = this.requForm.smpl_list.concat(listCopy);
            for (let i = 0; i < this.requForm.smpl_list.length; i++) {
              this.requForm.smpl_list[i].key = Number(i);
              if (this.requForm.smpl_list[i].smpl_deil_time !== 0) {
                this.requForm.smpl_list[i].smpl_deil_time = Number(this.requForm.smpl_list[i].smpl_deil_time) * 1000;
              } else {
                this.requForm.smpl_list[i].smpl_deil_time = null;
              }
              if (this.requForm.smpl_list[i].smpl_verify_price !== null && this.requForm.smpl_list[i].toys_type === 0) {
                this.requForm.smpl_list[i].smpl_verify_price = this.helper.haveFour(this.requForm.smpl_list[i].smpl_verify_price);
              }
              if (this.requForm.smpl_list[i].smpl_price !== null && this.requForm.smpl_list[i].toys_type === 0) {
                this.requForm.smpl_list[i].smpl_price = this.helper.haveFour(this.requForm.smpl_list[i].smpl_price);
              }
            }
            if (this.requForm.belo_dept_id === 0) {
              this.requForm.belo_dept_id = null;
            }
            // this.changeCustId(this.requForm.cust_id)
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.requForm.stff_name;
            this.stffForm.dept_name = this.requForm.dept_name;
            this.stffForm.dept_team_name = this.requForm.dept_team_name;
            this.stffForm.user_id = this.requForm.user_id;
            this.stffForm.dept_id = this.requForm.dept_id;
            this.stffForm.stff_id = this.requForm.stff_id;
            //获取接受状态方法
            this.getStatusName(this.requForm.requ_status);
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    exportToExcel() {
      this.$confirm('确定下载EXCEL?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const str = this.$route.query.form_id;
        getNoCatch(requAPI.export_requ_by_id, { requ_id: str, type: 1 }).then(({ data }) => {
          this.helper.downloadItemExcel(data.pdf_url, `${data.fileName}.xls`);
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}

.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}

.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_mrt1 {
  margin-top: 1px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_get {
  position: absolute;
  top: 0;
  left: 120px;
  margin-left: 10px;
}

.vd_Back {
  position: absolute;
  top: 0;
  left: 185px;
}

.vd_solidDiv {
  border-top: #dcdfe6 solid 1px;
  margin: 20px 0;
}

.vd_but {
  margin-bottom: 10px;
}

.flexHV {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
